import React from "react"
import SEO from "../../components/seo"
import WallBlackBackground from "../../components/backgroundImages/wallBlackBackground";
import PaintWall from "../../components/backgroundImages/paintWall";

import Paint from "../../images/paint.png"
import BarCeresSelect from "../../components/barCeresSelect";

const BarCeresPage = () => {

  return (
    <main>
      <SEO
        title="Bar Ceres"
        description=""
      />

      <div className="wall__background-wrapper">

        <WallBlackBackground />

        <PaintWall position={'top-right'} image={Paint} />

        <BarCeresSelect />


      </div>
    </main>
  );

};

export default BarCeresPage;
