import React, {useState} from 'react';

import PlusSVG from "../../images/svg/accordion-plus.svg";

import "./barCeresAccordion.scss"

const BarCeresAccordion = (props) => {

  const {
    title,
    children
  } = props;

  const [accordionOpen,setAccordionOpen] = useState(false);

  return (
    <div
      className={`bar-ceres-accordion__wrapper ${ accordionOpen ? "is-open" : '' }`}
    >
      <div
        className="bar-ceres-accordion__title"
        aria-expanded={accordionOpen}
        aria-controls={title.split(" ").join("-").toLowerCase()}
        id={`accordion-${title
          .split(" ")
          .join("-")
          .toLowerCase()}`}
        // detect event analytics only opening the accordion
        onClick={() => { setAccordionOpen(prevOpen => !prevOpen) }}
      >
        {title}
        <PlusSVG className="accordion-plus" />
        {/*<ReactSVG className="accordion-plus" src={AccordionPlus} />*/}
        {/*<div className="accordion-plus">X</div>*/}
      </div>
      <div
        id={title.split(" ").join("-").toLowerCase()}
        role="region"
        className="bar-ceres-accordion__content"
        aria-labelledby={`accordion-${title
          .split(" ")
          .join("-")
          .toLowerCase()}`}
      >
        {children}
      </div>
    </div>
  )
};

export default BarCeresAccordion;
