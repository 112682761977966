import React, {useEffect, useState} from 'react';

import Logo from "../../images/campioni-da-bar/campioni-da-bar-logo.png";

import "./barCeresSelect.scss"
import jsonData from "./barCeresSelect.yml";
import BarCeresAccordion from "../barCeresAccordion";
import VisibilitySensor from "../visibilitySensor";

const BarCeresSelect = () => {

  const [cities,setCities] = useState([]);
  const [city,setCity] = useState([]);
  const [provinces,setProvinces] = useState([]);
  const [province,setProvince] = useState([]);
  const [defaultSelect,setDefaultSelect] = useState("none");
  const [bar,setBar] = useState([]);

  useEffect(() => {
    jsonData.map((item) => {
      !provinces.includes(item.provincia) && provinces.push(item.provincia);
    });
    setProvinces(provinces.sort());
  }, []);

  useEffect(() => {
    jsonData.map((item) => {
      if (!cities[item.provincia]) {
        cities[item.provincia] = [];
      }
      !cities[item.provincia].includes(item.citta) &&
      cities[item.provincia].push(item.citta);
    });
    // eslint-disable-next-line no-unused-vars
    for (let provinces in cities) {
      cities[provinces] = cities[provinces].sort();
    }
    setCities(cities);
  }, []);

  useEffect(() => {
    setBar(jsonData);
  }, []);

  const onSelectProvincia = (e) => {
    setProvince(e.target.value);
    setDefaultSelect("none");
  }

  const onSelectCitta = (e) => {
    setCity(e.target.value);
    setDefaultSelect(e.target.value);
  }

  return (
    <div className="bar-ceres-select">
      <div className="bar-ceres-select__logo">
        <img src={Logo} alt=""/>
      </div>
      <div className="bar-ceres-select__wrapper">
        {/*Select Province*/}
        <select className="bar-ceres-select__select" name="provinces" title="provinces" id="select-provinces"
                onChange={(e) => {
                  onSelectProvincia(e)
                }}
        >
          <option value="">Provincia</option>
          {provinces.map((provinceOption, i) => {
            return (
              <option key={i} value={provinceOption}>
                {provinceOption}
              </option>
            );
          })}
        </select>

        {/*Select Città*/}
        <select className="bar-ceres-select__select" name="cities" title="cities" id="select-cities"
                onChange={(e) => {
                  onSelectCitta(e)
                }}
                value={defaultSelect}
        >
          <option value="none">Città</option>
          {province.length > 0 &&
            (
              <>
                {cities[province].map((cityOption, i) => {
                  return (
                    <option key={i} value={cityOption}>
                      {cityOption}
                    </option>
                  );
                })}
              </>
            )
          }
        </select>
      </div>

      <div className="bar-ceres-select__results">

        {city.length > 0 && (
          <>
            <div className="bar-ceres-select__results-header">
              <span>Bar</span>
              <span>Barista</span>
              <span>Soprannome</span>
              <span>Provincia</span>
              <span>Città</span>
              <span>Indirizzo</span>
            </div>
            {bar
              .filter((item) => {
                return (
                  item.provincia === province &&
                  item.citta === city
                );
              })
              .map((item, key) => {
                return (
                  <VisibilitySensor key={key}>
                    {({ isVisible }) => (
                      <div className={`${isVisible ? 'slideUpShort sensorEnter sensorNoDelay' : 'slideUpShort'}`}>
                        <BarCeresAccordion title={item.nomeBar}>
                          <div className="bar-ceres-select__results-item">
                            <div className="bar-ceres-select__results-label">
                              <span>Bar</span>
                            </div>
                            <div>{item.nomeBar}</div>
                          </div>

                          <div className="bar-ceres-select__results-item">
                            <div className="bar-ceres-select__results-label">
                              <span>Barista</span>
                            </div>
                            <div>{item.nomeBarista}</div>
                          </div>

                          <div className="bar-ceres-select__results-item">
                            <div className="bar-ceres-select__results-label">
                              <span>Soprannome</span>
                            </div>
                            <div>{item.soprannome}</div>
                          </div>

                          <div className="bar-ceres-select__results-item">
                            <div className="bar-ceres-select__results-label">
                              <span>Provincia</span>
                            </div>
                            <div>{item.provincia}</div>
                          </div>

                          <div className="bar-ceres-select__results-item">
                            <div className="bar-ceres-select__results-label">
                              <span>Città</span>
                            </div>
                            <div>{item.citta}</div>
                          </div>

                          <div className="bar-ceres-select__results-item">
                            <div className="bar-ceres-select__results-label">
                              <span>Indirizzo</span>
                            </div>
                            <div>{item.indirizzo}</div>
                          </div>

                        </BarCeresAccordion>
                      </div>
                    )}
                  </VisibilitySensor>
                );
              })}
          </>
        )}

      </div>

    </div>
  )
};

export default BarCeresSelect;
